import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-vendor-invoices',
  templateUrl: './vendor-invoices.page.html',
  styleUrls: ['./vendor-invoices.page.scss'],
})
export class VendorInvoicesPage implements OnInit {

  orderDetails;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  close() {
    this.modalController.dismiss();
  }

  downloadInvoice(invoiceUrl){
    console.log('url:',invoiceUrl);
    window.open(invoiceUrl, "_blank");

  }

}
