import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Events } from "@ionic/angular";
import algoliasearch from "algoliasearch";
import { environment } from "src/environments/environment";
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../config/config.service";

declare var Typesense;
@Injectable({
  providedIn: "root",
})
export class SearchEngineService {
  client: any;
  index: any;
  ALGOLIA_APP_ID = this.configService.environment.ALGOLIA_APP_ID;
  ALGOLIA_APP_KEY = this.configService.environment.ALGOLIA_APP_KEY;
  APP_PROJECT_ID = environment.firebase.projectId;
  page: number = 1;
  products: any = [];
  adminProducts: any = [];
  typesenseCred = this.configService.environment.typesense;
  typesenseClient;
  useTypesense = this.configService.environment.useTypesense;
  constructor(
    private afs: AngularFirestore,
    private events: Events,
    private sharedService: SharedService,
    private configService: ConfigService
  ) {}
  initializeSubscriptions() {
    this.events.subscribe(
      "search-engine:alogoliaSearchProducts",
      (searchValue, page, type) => {
        this.alogoliaSearchProducts(searchValue, page, type);
      }
    );

	this.events.subscribe("search-engine:algoliaSearchProductsInCategory",
	  (searchValue, page, type) => {
		this.algoliaSearchProductsInCategory(searchValue, page, type);
	  }
	);

    this.events.subscribe(
      "search-engine:getProductsForSearchResults",
      (searchValue, page, type) => {
        this.getProductsForSearchResults(searchValue, page, type);
      }
    );
    if (this.typesenseCred && this.useTypesense) {
      this.typesenseClient = new Typesense.SearchClient({
        nodes: [
          {
            host: this.typesenseCred.host,
            port: this.typesenseCred.port,
            protocol: this.typesenseCred.protocol,
          },
        ],
        apiKey: this.typesenseCred.searchOnlyKey,
        connectionTimeoutSeconds: 2,
      });
    }
  }

  async alogoliaSearchProducts(
    searchValue: string,
    page: number = 0,
    type: string
  ) {
    let searchRes = { status: "", products: [] };
    if (this.useTypesense) {
      console.log("useTypesense....");
      searchRes = await this.getSearchProductsFromTypesense(
        searchValue,
        page,
        type
      );
    } else {
      searchRes = await this.getSearchProductsFromAlgolia(
        searchValue,
        page,
        type
      );
    }

    if (searchRes.status === "no_products") {
      this.events.publish("search-engine:noSearchProductsAvailable");
    } else if (searchRes.status === "no_more_products") {
      this.events.publish("search-engine:noMoreSearchProducts");
    } else {
      this.events.publish(
        "search-engine:productSearchResults",
        searchRes.products
      );
    }
    this.saveAnalytics(searchValue);
  }

  async algoliaSearchProductsInCategory(searchValue: string, page: number = 0, type: string) {
	console.log('cat search');
	let searchRes = { status: "", products: [] };
    if (this.useTypesense) {
      console.log("useTypesense....");
      searchRes = await this.getSearchProductsFromTypesense(
        searchValue,
        page,
        type,
		'',
		true
      );
    } else {
      searchRes = await this.getSearchProductsFromAlgolia(
        searchValue,
        page,
        type
      );
    }

    if (searchRes.status === "no_products") {
      this.events.publish("search-engine:noCategorySearchProductsAvailable");
    } else if (searchRes.status === "no_more_products") {
      this.events.publish("search-engine:noMoreCategorySearchProducts");
    } else {
      this.events.publish(
        "search-engine:productCategorySearchResults",
        searchRes.products
      );
    }
    this.saveAnalytics(searchValue);
  }

  async getProductsForSearchResults(
    searchValue: string,
    page: number,
    type: string
  ) {
    try {
      let searchRes = null;
      if (this.useTypesense) {
        searchRes = await this.getSearchProductsFromTypesense(
          searchValue,
          page,
          type
        );
      } else {
        searchRes = await this.getSearchProductsFromAlgolia(
          searchValue,
          page,
          type
        );
      }

      if (searchRes.status === "no_products") {
        this.events.publish("search-engine:noProductsForSearchResults");
      } else if (searchRes.status === "no_more_products") {
        this.events.publish("search-engine:noMoreProductsForSearchResults");
      } else {
        this.events.publish(
          "search-engine:publishProductForSearchResults",
          searchRes.products
        );
      }
      this.saveAnalytics(searchValue);
    } catch (error) {
      console.log(error);
      this.events.publish("search-engine:noProductsForSearchResults");
    }
  }

  getSearchProductsFromAlgolia(
    searchValue: string,
    page: number = 0,
    type: string
  ) {
    return new Promise<{ status: string; products: any[] }>(
      async (resolve, reject) => {
        try {
          if (type === "new_search") {
            this.products = [];
          }
          const region = await this.sharedService.checkRegionIdForApi();
          let regionId = region.regionId;
          let vendorId = region.vendorId;
          let filters = "status:true";
          if (regionId) {
            filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`;
          }
          if (vendorId) {
            filters += ` AND vendorId:${vendorId}`;
          }

          this.client = algoliasearch(
            this.ALGOLIA_APP_ID,
            this.ALGOLIA_APP_KEY
          );
          this.index = this.client.initIndex(this.APP_PROJECT_ID);
          this.index
            .search(searchValue, { page: page, filters })
            .then((result) => {
              // console.log(result);
              if (result.nbPages === 0) {
                resolve({ status: "no_products", products: [] });
              } else if (result.hits.length === 0 && page === result.nbPages) {
                resolve({ status: "no_more_products", products: [] });
              } else {
                result.hits.forEach((h) => {
                  this.products.push({ ...h, id: h.objectID });
                });
                resolve({ status: "available", products: this.products });
              }
            })
            .catch(async (err) => {
              //console.log(err);
              resolve({ status: "no_products", products: [] });
            });

          this.saveAnalytics(searchValue);
        } catch (error) {
          //console.log(error);
          resolve({ status: "no_products", products: [] });
        }
      }
    );
  }

  getSearchProductsFromTypesense(
    searchValue: string,
    page: number = 0,
    type: string,
    filterArg?: string,
	categorySearch?: boolean
  ) {
    return new Promise<{ status: string; products: any[] }>(
      async (resolve, reject) => {
        if (type === "new_search") {
          this.products = [];
        }
        page += 1;
        const region = await this.sharedService.checkRegionIdForApi();
        let regionId = region.regionId;
        let vendorId = region.vendorId;
        let filters = "status:=true";
        if (vendorId) {
          filters += ` && vendorId:=${vendorId}`;
        }

		if (categorySearch) {
			filters = this.addCategoryFilter(filters);
		}
        let searchRequests = {
          searches: [
            {
              collection: `${environment.firebase.projectId}-products`,
              q: searchValue,
              filter_by: filterArg || filters,
              page: page,
              per_page: 20,
            },
          ],
        };

        if (regionId) {
          searchRequests.searches[0].filter_by += ` && categoryRegions:=${regionId}`;
          searchRequests.searches.push({
            collection: `${environment.firebase.projectId}-products`,
            q: searchValue,
            filter_by: `${filterArg || filters} && brandRegions:=${regionId}`,
            page: page,
            per_page: 20,
          });
        }

        let commonSearchParams = {
          query_by: "prodName",
        };

        this.typesenseClient.multiSearch
          .perform(searchRequests, commonSearchParams)
          .then((response) => {
            console.log(response);
            if (!response || !response.results || !response.results.length) {
              resolve({ status: "no_products", products: [] });
            } else {
              let noResults = true;
              let noMoreResults = true;
              response.results.forEach((result) => {
                if (result.found > 0) {
                  noResults = false;
                }
                if (result.hits.length) {
                  noMoreResults = false;
                }
                result.hits.forEach((h) => {
                  if (
                    !this.products.length ||
                    this.products.some(
                      (product) => product.id !== h.document.id
                    )
                  ) {
                    h.document = this.parseArrayOfObjects(h.document);
                    this.products.push({
                      ...h.document,
                      id: h.document.id,
                      objectID: h.document.id,
                    });
                  }
                });
              });
              if (noResults) {
                console.log("noResults");
                resolve({ status: "no_products", products: [] });
              } else if (noMoreResults) {
                console.log("noMoreResults");
                resolve({ status: "no_more_products", products: [] });
              } else {
                console.log("available", this.products);
                resolve({ status: "available", products: this.products });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            resolve({ status: "no_products", products: [] });
          });
      }
    );
  }

  addCategoryFilter(filters: string) {
	const pathName = window.location.pathname;
	if (pathName.startsWith('/shop/category/') || pathName.startsWith('/shop-subcategories/')) {
		const lastSlash = pathName.lastIndexOf('/');
		// The following check ensures that at least some string is present in the url in category's place.
		if (lastSlash + 1 < pathName.length) {
			let categoryId = pathName.substring(lastSlash + 1);
			if (categoryId) {
				filters += ` && categories:=${categoryId}`;
			}
		}
	}

	return filters;
  }

  parseArrayOfObjects(object) {
    for (const key in object) {
      try {
        const value = JSON.parse(object[key]);
        if (
          (Array.isArray(value) &&
            value.length &&
            typeof value[0] == "object") ||
          (Array.isArray(value) && !value.length)
        ) {
          object[key] = value;
        }
      } catch (error) {}
    }
    return object;
  }

  async saveAnalytics(value: string) {
    const uid = await this.sharedService.getStorageUid();
    if (uid) {
      const data = {
        value,
        searchedAt: new Date(),
        source: "web",
      };
      this.afs
        .collection("users")
        .doc(uid)
        .collection("analytics")
        .doc("search")
        .collection("data")
        .add(data);
    }
  }
}
