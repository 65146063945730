<ion-header>
  <ion-toolbar class="toolbar">
    <ion-title text-center *ngIf="!editAddressData">{{'NEW_ADDRESS.new_address' | translate}}</ion-title>
    <ion-title text-center *ngIf="editAddressData">{{'NEW_ADDRESS.edit_address' | translate}}</ion-title>
    <ion-buttons slot="end"  >
      <ion-button (click)="modalDismiss()">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <!-- New Address -->
  <ion-grid class="ion-no-padding" id="grid-content" *ngIf="!editAddressData">
    <ion-row>
      <ion-col size="12">
        <div class="input-wrap">
          <ion-label>{{'NEW_ADDRESS.name' | translate}}</ion-label>
          <ion-input class="form-input"  [(ngModel)]="addressObj.name" autocapitalize></ion-input>
        </div>
      </ion-col>
      <ion-col size="12">
        <div class="input-wrap">
          <div class="space-between">
            <ion-label>{{'NEW_ADDRESS.complete_address' | translate}}</ion-label>
            <ion-button (click)="showMapModal()" shape="round">
              {{'NEW_ADDRESS.pick_address' | translate}}
          </ion-button>
        </div>
          <ion-textarea type="text" rows="3" class="form-input" [(ngModel)]="addressObj.address" autocapitalize></ion-textarea>
        </div>
      </ion-col>
      <ion-col size="6">
        <div class="input-wrap">
          <ion-label>{{'NEW_ADDRESS.city' | translate}}</ion-label>
          <ion-input class="form-input" [(ngModel)]="addressObj.city" autocapitalize></ion-input>
        </div>
      </ion-col>
      <ion-col size="6">
        <div class="input-wrap" (click)="openStateModal()">
          <ion-label>{{'NEW_ADDRESS.state' | translate}}</ion-label>
          <ion-input class="form-input" [(ngModel)]="addressObj.state" autocapitalize></ion-input>
        </div>
      </ion-col>
      <ion-col size="12">
        <div class="input-wrap">
          <ion-label>{{getPincodeHeading() | translate}}</ion-label>
          <ion-input type="text" [ngClass]="{'invalid-border': !isPincodeValid()}" class="form-input" [(ngModel)]="addressObj.pincode" autocapitalize></ion-input>
        </div>
        <ng-container *ngIf="!isPincodeValid()">
          <ion-text color="danger" class="d-flex-start info-txt-w-icon">
            <ion-icon name="information-circle"></ion-icon>
            <span>
              {{'NEW_ADDRESS.invalid_pincode' | translate }}
            </span>
          </ion-text>
        </ng-container>
      </ion-col>
      <ion-col size="12">
        <div class="input-wrap">
          <ion-label>{{'NEW_ADDRESS.phone_no' | translate}}</ion-label>
          <ion-input type="tel" class="form-input" [(ngModel)]="addressObj.phoneNo" autocapitalize></ion-input>
        </div>
      </ion-col>
    </ion-row>
    </ion-grid>
  <!-- /New Address -->


  <!-- Edit Address -->
  <ion-grid class="ion-no-padding" id="grid-content" *ngIf="editAddressData">
    <ion-row>
      <ion-col size="12">
        <div class="input-wrap">
          <ion-label>{{'NEW_ADDRESS.name' | translate}}</ion-label>
          <ion-input class="form-input" [(ngModel)]="editAddressData.name" autocapitalize></ion-input>
        </div>
      </ion-col>
      <ion-col size="12">
        <div class="input-wrap">
          <div class="space-between">
            <ion-label>{{'NEW_ADDRESS.complete_address' | translate}}</ion-label>
            <ion-button (click)="showMapModal()" shape="round">
              {{'NEW_ADDRESS.pick_address' | translate}}
          </ion-button>
        </div>
          <ion-textarea type="text" rows="3" class="form-input" [(ngModel)]="editAddressData.address" autocapitalize (ngModelChange)="addressChange()"></ion-textarea>
        </div>
      </ion-col>
      <ion-col size="6">
        <div class="input-wrap">
          <ion-label>{{'NEW_ADDRESS.city' | translate}}</ion-label>
          <ion-input class="form-input" [(ngModel)]="editAddressData.city" autocapitalize></ion-input>
        </div>
      </ion-col>
      <ion-col size="6">
        <div class="input-wrap" (click)="openStateModal()">
          <ion-label>{{'NEW_ADDRESS.state' | translate}}</ion-label>
          <ion-input class="form-input" [(ngModel)]="editAddressData.state" autocapitalize></ion-input>
        </div>
      </ion-col>
      <ion-col size="12">
        <div class="input-wrap">
          <ion-label>{{getPincodeHeading() | translate}}</ion-label>
          <ion-input type="text" [ngClass]="{'invalid-border': !isPincodeValid()}" class="form-input" [(ngModel)]="editAddressData.pincode" autocapitalize></ion-input>
        </div>
        <ng-container *ngIf="!isPincodeValid()">
          <ion-text color="danger" class="d-flex-start info-txt-w-icon">
            <ion-icon name="information-circle"></ion-icon>
            <span>
              {{'NEW_ADDRESS.invalid_pincode' | translate }}
            </span>
          </ion-text>
        </ng-container>
      </ion-col>
      <ion-col size="12">
        <div class="input-wrap">
          <ion-label>{{'NEW_ADDRESS.phone_no' | translate}}</ion-label>
          <ion-input type="tel" class="form-input" [(ngModel)]="editAddressData.phoneNo" autocapitalize></ion-input>
        </div>
      </ion-col>
    </ion-row>
    </ion-grid>
  <!-- edit Address-->
  

</ion-content>


<ion-footer>
  <ion-button class="btn-1 i-start" expand="full" (click)="onClickSaveAddress()" *ngIf="!editAddressData && !routeFromCheckoutPage && !routeFromSelectAddress">
    <i class="flaticon-null-20 app-footer-icon"></i>
    {{'NEW_ADDRESS.save' | translate}}
  </ion-button>
  
  <ion-button class="btn-1 i-start" expand="full" (click)="onClickEditAddress()" *ngIf="editAddressData">
    <i class="flaticon-null-20 app-footer-icon"></i>
    {{'NEW_ADDRESS.update' | translate}}
  </ion-button>

  <ion-button class="btn-1 i-start" expand="full" (click)="onClickSaveAddress()"  *ngIf="(routeFromCheckoutPage || routeFromSelectAddress) && !editAddressData">
    <i class="flaticon-null-20 app-footer-icon"></i>
    {{'NEW_ADDRESS.deliver_here' | translate}}
  </ion-button>
</ion-footer>