import { ConfigService } from '../config/config.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
export class SharedNewService {
    constructor(configService) {
        this.configService = configService;
    }
    showQtyLeftMsg(product, index = 0) {
        const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        let quantity = 0;
        quantity = product.isPriceList ? +product.priceList[index].totalQuantity || 0 : +product.productQty || 0;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    }
    showPriceListQtyLeftMsg(quantity) {
        const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    }
}
SharedNewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedNewService_Factory() { return new SharedNewService(i0.ɵɵinject(i1.ConfigService)); }, token: SharedNewService, providedIn: "root" });
