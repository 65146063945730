import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { ConsoleService } from '../console/console.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../console/console.service";
export class DesignStudioService {
    constructor(angularFirestore, consoleService) {
        this.angularFirestore = angularFirestore;
        this.consoleService = consoleService;
        this.designStudioActive = false;
    }
    initializeService() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let data = yield this.angularFirestore.collection('studio').doc('website').valueChanges().pipe(first()).toPromise();
            this.designStudioActive = data.active;
            console.log('designStudioActive', this.designStudioActive);
            this.getGlobalData();
        });
    }
    setHeaderData(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.angularFirestore.collection('studio').doc('website').collection('sections').doc('header').set(data);
        });
    }
    setCatgData(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.angularFirestore.collection('studio').doc('website').collection('sections').doc('catgs').set(data);
        });
    }
    getSessionStorageGlobalData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let data = JSON.parse(localStorage.getItem("globalData")) || {};
                if (!(Object.keys(data).length)) {
                    data = yield this.getGlobalData();
                    localStorage.setItem("globalData", JSON.stringify(data));
                }
                resolve(data);
            }));
        });
    }
    getGlobalData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.consoleService.consoleLog('design-studio.service:44 getHeaderData designStudioActive', this.designStudioActive)
            let data = yield this.angularFirestore.collection('studio').doc('website').valueChanges().pipe(first()).toPromise();
            let designStudioActive = data.active;
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let globalData;
                if (designStudioActive) {
                    console.log('designStudio is Active');
                    globalData = yield this.angularFirestore.collection('studio').doc('website').collection('sections').doc('global').valueChanges().pipe(first()).toPromise();
                }
                else {
                    console.log('designStudio is deActive');
                    globalData = {
                        productStyle: {
                            gridColumn: {
                                sm: 1,
                                md: 3,
                                lg: 5
                            },
                            fixedHeight: 150,
                            isFixedHeight: true,
                            style: "product-one"
                        },
                        itemStyle: {
                            isFixedHeight: true,
                            fixedHeight: 200,
                            showFilter: true,
                            gridColumn: {
                                lg: 5,
                                md: 3,
                                sm: 1
                            },
                            style: "category-one"
                        }
                    };
                }
                resolve(globalData);
            }));
        });
    }
    getSessionStorageHeaderData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let data = JSON.parse(sessionStorage.getItem("headerData")) || {};
                if (!(Object.keys(data).length)) {
                    data = yield this.getHeaderData();
                    sessionStorage.setItem("headerData", JSON.stringify(data));
                }
                resolve(data);
            }));
        });
    }
    getHeaderData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.consoleService.consoleLog('design-studio.service:44 getHeaderData designStudioActive', this.designStudioActive)
            let data = yield this.angularFirestore.collection('studio').doc('website').valueChanges().pipe(first()).toPromise();
            let designStudioActive = data.active;
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let headerDdata = {};
                if (designStudioActive) {
                    console.log('designStudio is Active');
                    headerDdata = yield this.angularFirestore.collection('studio').doc('website').collection('sections').doc('header').valueChanges().pipe(first()).toPromise();
                    headerDdata.designStudioActive = true;
                }
                else {
                    console.log('designStudio is deActive');
                    headerDdata.designStudioActive = false;
                }
                resolve(headerDdata);
            }));
        });
    }
}
DesignStudioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DesignStudioService_Factory() { return new DesignStudioService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.ConsoleService)); }, token: DesignStudioService, providedIn: "root" });
