import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first, take } from 'rxjs/operators';
import { ConsoleService } from '../console/console.service';

@Injectable({
    providedIn: 'root'
})
export class DesignStudioService {
    headerData;
    footerData;
    designStudioActive = false;
    globalData;

    constructor(
        private angularFirestore: AngularFirestore,
        private consoleService:ConsoleService,
    ) { }
    async initializeService() {
        let data:any = await this.angularFirestore.collection('studio').doc('website').valueChanges().pipe(first()).toPromise();
        this.designStudioActive = data.active;
        console.log('designStudioActive', this.designStudioActive);
        this.getGlobalData();
    }

    async setHeaderData(data) {
        await this.angularFirestore.collection('studio').doc('website').collection('sections').doc('header').set(data);
    }

    async setCatgData(data) {
        await this.angularFirestore.collection('studio').doc('website').collection('sections').doc('catgs').set(data);
    }

    async getSessionStorageGlobalData() {
        return new Promise(async (resolve, reject) => {
            let data = JSON.parse(localStorage.getItem("globalData")) || {};
            if (!(Object.keys(data).length)) {
              data = await this.getGlobalData();
              localStorage.setItem("globalData", JSON.stringify(data));
            }
            resolve(data);
        });
    }

    async getGlobalData() {
        //this.consoleService.consoleLog('design-studio.service:44 getHeaderData designStudioActive', this.designStudioActive)
        let data:any = await this.angularFirestore.collection('studio').doc('website').valueChanges().pipe(first()).toPromise();
        let designStudioActive = data.active;
        return new Promise(async (resolve, reject) => {
            let globalData;
            if (designStudioActive) {
                console.log('designStudio is Active');
                globalData = await this.angularFirestore.collection('studio').doc('website').collection('sections').doc('global').valueChanges().pipe(first()).toPromise();
            } else {
                console.log('designStudio is deActive');
                globalData = {
                    productStyle: {
                        gridColumn: {
                            sm: 1,
                            md: 3,
                            lg: 5
                        },
                        fixedHeight: 150,
                        isFixedHeight: true,
                        style: "product-one"
                    },
                    itemStyle: {
                        isFixedHeight: true,
                        fixedHeight: 200,
                        showFilter:true,
                        gridColumn: {
                            lg: 5,
                            md: 3,
                            sm: 1
                        },
                        style: "category-one"
                    }
                }
            }
            resolve(globalData);
        });
    }
    async getSessionStorageHeaderData() {
        return new Promise(async (resolve, reject) => {
            let data = JSON.parse(sessionStorage.getItem("headerData")) || {};
            if (!(Object.keys(data).length)) {
              data = await this.getHeaderData();
                sessionStorage.setItem("headerData", JSON.stringify(data));
            }
            resolve(data);
        });
    }

    async getHeaderData() {
        //this.consoleService.consoleLog('design-studio.service:44 getHeaderData designStudioActive', this.designStudioActive)
        let data:any = await this.angularFirestore.collection('studio').doc('website').valueChanges().pipe(first()).toPromise();
        let designStudioActive = data.active;
        return new Promise(async (resolve, reject) => {
            let headerDdata:any = {};
            if (designStudioActive) {
                console.log('designStudio is Active');
                headerDdata = await this.angularFirestore.collection('studio').doc('website').collection('sections').doc('header').valueChanges().pipe(first()).toPromise();
               headerDdata.designStudioActive = true;
            } else {
                console.log('designStudio is deActive');
                headerDdata.designStudioActive = false;
            }
            resolve(headerDdata);
        });
    }

   /* xxxxxxxxxxxxx async getHeaderData() {
        let data: any;
        return new Promise(async (resolve, reject) => {
            if (localStorage.getItem("headerData")) {
                const storageHeaderData = JSON.parse(localStorage.getItem("headerData")) || [];
                if (!(Object.keys(storageHeaderData).length)) {
                    data = await this.setHeaderDataInLocalStorage();
                } else {
                    data = storageHeaderData;
                } 
            }
            else{
                data = await this.setHeaderDataInLocalStorage();
            }
            resolve(data);
        });
    }

    setHeaderDataInLocalStorage() {
        let data: any;
        return new Promise(async (resolve, reject) => {
            data = await this.angularFirestore.collection('studio').doc('website').collection('sections').doc('header').valueChanges().pipe(first()).toPromise();
            localStorage.setItem("headerData", JSON.stringify(data));
            resolve(data);

        });

    }*/
}
