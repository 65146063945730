import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController, Events, AlertController, LoadingController, ToastController, Platform } from '@ionic/angular';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { CreateSubscriptionPage } from '../user-product-subscription/create-subscription/create-subscription.page';
import { ConfigService } from 'src/app/services/config/config.service';
import { SharedService } from '../services/shared/shared.service';
import { CartService } from '../services/cart/cart.service';
import { UtilsService } from '../services/utils/utils.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedNewService } from '../services/shared-new/shared-new.service';
import { AppointmentSchedulesPage } from '../appointment-schedules/appointment-schedules.page';
export class PricelistModalPage {
    constructor(modalController, events, alertController, loadingController, userService, router, storage, configService, toastController, sharedService, utilsService, labelService, platform, cartService, sharedNewService) {
        this.modalController = modalController;
        this.events = events;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.userService = userService;
        this.router = router;
        this.storage = storage;
        this.configService = configService;
        this.toastController = toastController;
        this.sharedService = sharedService;
        this.utilsService = utilsService;
        this.labelService = labelService;
        this.platform = platform;
        this.cartService = cartService;
        this.sharedNewService = sharedNewService;
        this.cartProducts = [];
        this.listOfWeights = [];
        this.mainListOfWeightsAndPrices = [];
        this.priceForRetail = false;
        this.SHARED_LABELS = {};
        this.PRICELIST_MODAL_LABLES = {};
        this.productURL = '';
    }
    ngOnInit() {
        this.platform.ready().then(() => {
            let deviceWidth = this.platform.width();
            if (deviceWidth > 786) {
                this.useThumb = false;
            }
            else {
                this.useThumb = true;
            }
        });
        this.currencyCode = this.configService.environment.currencyCode;
        this.priceForRetail = this.configService.environment.priceForRetail;
    }
    modalDismiss() {
        this.modalController.dismiss();
    }
    goToPage(page) {
        this.modalDismiss();
        this.router.navigate([page]);
    }
    ionViewWillEnter() {
        this.userId = this.userService.getUserId();
        this.initialDisplayOfWeights();
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.PRICELIST_MODAL_LABLES = this.labelService.labels['SHARED'];
    }
    getDiscountPercent(discountedPrice, prodPrice) {
        if (prodPrice > discountedPrice) {
            const discount = Math.round(((prodPrice - discountedPrice) / prodPrice) * 100);
            return discount;
        }
        else {
            return 0;
        }
    }
    initialDisplayOfWeights() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let deal = yield this.sharedService.checkLimitedTimeDeal(this.product.data);
            const subRole = yield this.storage.get('userSubRole');
            let retailDiscount = 0;
            let retailDiscountType = 'percentage';
            if (subRole && subRole === 'retailer' && this.priceForRetail) {
                retailDiscount = this.product.data.retailDiscount ? this.product.data.retailDiscount : 0;
                retailDiscountType = this.product.data.retailDiscountType || 'percentage';
            }
            for (const pl of this.product.data.priceList) {
                const index = this.cartProducts.findIndex(c => c.productId === this.product.id && c.pack.weight === pl.weight);
                if (index !== -1) {
                    pl.cartQty = this.cartProducts[index].quantity;
                    pl.cartId = this.cartProducts[index].id;
                }
                if (deal.dealTime) {
                    if (deal.discount > 0) {
                        pl.discountedPrice = pl.price - (pl.price * (deal.discount / 100));
                    }
                }
                if (retailDiscount) {
                    if (retailDiscountType === 'percentage') {
                        pl.discountedPrice = pl.price - (pl.price * (retailDiscount / 100));
                    }
                    else {
                        pl.discountedPrice = pl.price - retailDiscount;
                    }
                }
            }
            this.mainListOfWeightsAndPrices = this.product.data.priceList;
        });
    }
    addProductToCart(index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isCartAvailable = yield this.cartService.checkCartAvailability(this.product.data.inactiveByVendor);
            if (isCartAvailable) {
                const cartObj = this.cartService.getPriceListCartObj(this.product, index);
                const cartId = yield this.cartService.addProductToCart(cartObj);
                this.mainListOfWeightsAndPrices[index].cartId = cartId;
                this.mainListOfWeightsAndPrices[index].cartQty = 1;
            }
        });
    }
    decrementQuantity(index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.mainListOfWeightsAndPrices[index].cartQty > 1) {
                this.mainListOfWeightsAndPrices[index].cartQty -= 1;
                this.cartService.updateCartState(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty);
                this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] || null;
                clearTimeout(this.mainListOfWeightsAndPrices[index]['cartUpdateTimer']);
                this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = setTimeout(() => {
                    this.cartService.updateCartProduct(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty);
                }, 800);
            }
            else {
                this.cartService.deleteCartProduct(this.mainListOfWeightsAndPrices[index].cartId);
            }
        });
    }
    incrementQuantity(index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.mainListOfWeightsAndPrices[index].cartQty += 1;
            this.cartService.updateCartState(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty);
            this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] || null;
            clearTimeout(this.mainListOfWeightsAndPrices[index]['cartUpdateTimer']);
            this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = setTimeout(() => {
                this.cartService.updateCartProduct(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty);
            }, 800);
        });
    }
    closePriceListModal() {
        this.modalController.dismiss();
    }
    goToCart() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isCartAvailable = yield this.cartService.checkCartAvailability();
            if (isCartAvailable) {
                this.closePriceListModal();
                this.router.navigate(['user-cart']);
            }
        });
    }
    presentAlert(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                buttons: this.SHARED_LABELS['ok']
            });
            yield alert.present();
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.SHARED_LABELS['please_wait'],
                duration: 5000
            });
            yield this.loading.present();
        });
    }
    getDiscountedProduct(price, discount) {
        let discountedPrice = Math.ceil(price - (price * ((discount * 1) / 100)));
        return discountedPrice;
    }
    maxQtyReached() {
        this.presentToast(`${this.PRICELIST_MODAL_LABLES['max_qty_reached']}`);
    }
    presentToast(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message: msg,
                duration: 2000
            });
            toast.present();
        });
    }
    subscribe(i) {
        this.product.data.prodPrice = this.product.data.priceList[i].price;
        this.product.data.discountedPrice = this.product.data.priceList[i].discountedPrice;
        this.product.data['variantValue'] = this.product.data.priceList[i].weight;
        this.product.data['productCode'] = 'sku' in this.product.data.priceList[i] ? this.product.data.priceList[i].sku : this.product.data.productCode ? this.product.data.productCode : '';
        this.product.data['barcodeNo'] = this.product.data.priceList[i].barcodeNo || '';
        this.closePriceListModal();
        this.modalController.create({
            component: CreateSubscriptionPage,
            cssClass: 'custom-modal',
            componentProps: {
                product: this.product,
                subSettings: this.subSettings,
            }
        }).then(modalEl => { modalEl.present(); });
    }
    isProductInCart(product) {
        return this.cartService.isProductInCart(product);
    }
    showQtyLeftMsg(qty) {
        return this.sharedNewService.showPriceListQtyLeftMsg(qty || 0);
    }
    getPriceListWeight(element) {
        if (this.product.data.variantType !== 'pieces') {
            return element.weight;
        }
        else {
            if ('name' in element) {
                return element.name;
            }
            else {
                return element.weight;
            }
        }
    }
    bookAppointment(index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const variant = this.product.data.appointment.schedules.variant.filter(s => s.name === this.product.data.priceList[index].weight);
            const modal = yield this.modalController.create({
                component: AppointmentSchedulesPage,
                componentProps: {
                    item: {
                        name: this.product.data.prodName,
                        price: this.product.data.priceList[index].discountedPrice,
                        coverPic: this.product.data.coverPic,
                        id: this.product.id,
                        variant: this.product.data.priceList[index].weight
                    },
                    vendor: {
                        id: this.product.data.vendorId || ''
                    },
                    maxDays: variant.length ? variant[0].maxDays : 0,
                    schedules: variant.length ? variant[0].schedules : []
                }
            });
            yield modal.present();
            modal.onDidDismiss()
                .then((data) => {
                if (data.data) {
                    if (data.data.booked) {
                        setTimeout(() => {
                            this.modalDismiss();
                        }, 100);
                    }
                }
            });
        });
    }
}
