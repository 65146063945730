import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { convertSnaps } from '../db-utils';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@ionic/storage";
import * as i4 from "../loggly-logger/loggly-logger.service";
export class DeliverySettingsService {
    constructor(events, afs, storage, logglyService) {
        this.events = events;
        this.afs = afs;
        this.storage = storage;
        this.logglyService = logglyService;
        this.deliveryRef = this.afs.collection('features').doc('delivery');
    }
    initializeSubscriptions() {
        this.events.subscribe('delivery-settings:saveDeliverySettings', (data) => {
            this.saveDeliverySettings(data);
        });
        this.events.subscribe('delivery-settings:getDeliverySettingsData', () => {
            this.getDeliverySettingsData();
        });
        this.events.subscribe('delivery-settings:getOrderPaymentDetails', (address, products, isGstApplicable, customDeliverySettings) => {
            this.getOrderPaymentDetails(address, products, isGstApplicable, customDeliverySettings);
        });
        this.events.subscribe('delivery-settings:checkPincode', (pincode) => {
            this.checkPincode(pincode);
        });
        this.events.subscribe('delivery-settings:getSlotsWithDate', (date) => {
            this.getSlotsWithDate(date);
        });
        this.events.subscribe('delivery-settings:updateSlot', (data) => {
            this.updateSlot(data);
        });
        this.events.subscribe('delivery-settings:getManageSlots', () => {
            this.getManageSlots();
        });
    }
    saveDeliverySettings(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.deliveryRef.set(data);
                this.events.publish('delivery-settings:saveDeliverySettingsSuccess');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'delivery-settings-service:saveDeliverySettings';
                this.logglyService.log(error);
            }
        });
    }
    getDeliverySettingsData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const deliveryData = yield this.deliveryRef.valueChanges().pipe(first()).toPromise();
                this.events.publish('delivery-settings:publishDeliverySettingsData', deliveryData);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'delivery-settings-service:getDeliverySettingsData';
                this.logglyService.log(error);
            }
        });
    }
    getDeliverySettingsDataInSessionStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let data = JSON.parse(sessionStorage.getItem("DeliverySettingsData")) || {};
                if (!(Object.keys(data).length)) {
                    data = yield this.deliveryRef.valueChanges().pipe(first()).toPromise();
                    sessionStorage.setItem("DeliverySettingsData", JSON.stringify(data));
                }
                resolve(data);
            }));
        });
    }
    deliverySettingsDataInStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const deliveryData = yield this.deliveryRef.valueChanges().pipe(first()).toPromise();
                this.storage.set('deliverySettings', deliveryData);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'delivery-settings-service:deliverySettingsDataInStorage';
                this.logglyService.log(error);
            }
        });
    }
    getOrderPaymentDetails(address, products, isGstApplicable, customDeliverySettings) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                console.log('customDeliverySettings', customDeliverySettings);
                let orderPaymentDetails = firebase.functions().httpsCallable('orders-getOrderPaymentDetails');
                orderPaymentDetails({ address, products, isGstApplicable, customDeliverySettings }).then((response) => {
                    // console.log('response orderPaymentDetails', response.data);
                    this.events.publish('delivery-settings:publishOrderPaymentDetails', response.data);
                });
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'delivery-settings-service:getOrderPaymentDetails';
                this.logglyService.log(error);
            }
        });
    }
    checkPincode(pincode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const deliveryData = yield this.deliveryRef.valueChanges().pipe(first()).toPromise();
                const pincodes = deliveryData.deliveryPincodes;
                let isAvailable = false;
                for (const p of pincodes) {
                    if (p.pincode === pincode) {
                        isAvailable = true;
                        break;
                    }
                }
                if (deliveryData.isAllowAllPincodes) {
                    this.events.publish('delivery-settings:pincodeDeliverable');
                }
                else {
                    if (isAvailable) {
                        this.events.publish('delivery-settings:pincodeDeliverable');
                    }
                    else {
                        this.events.publish('delivery-settings:pincodeNotDeliverable');
                    }
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'delivery-settings-service:checkPincode';
                this.logglyService.log(error);
            }
        });
    }
    getSlotsWithDate(date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const slotsDoc = yield this.deliveryRef.collection('manageSlots').doc(date).valueChanges().pipe(first()).toPromise();
                this.events.publish('delivery-settings:slotsWithDate', slotsDoc);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'delivery-settings-service:getSlotsWithDate';
                this.logglyService.log(error);
            }
        });
    }
    updateSlot(slotData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const date = slotData.date.toDateString();
                yield this.deliveryRef.collection('manageSlots').doc(date).set({ slots: slotData.slots });
                this.events.publish('delivery-settings:slotUpdated');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'delivery-settings-service:updateSlot';
                this.logglyService.log(error);
            }
        });
    }
    getManageSlots() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const manageSlots = yield this.deliveryRef.collection('manageSlots').snapshotChanges().pipe(map((actions) => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                this.events.publish('delivery-settings:publishManageSlots', manageSlots);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'delivery-settings-service:getManageSlots';
                this.logglyService.log(error);
            }
        });
    }
    getCustomDelivery() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const customDelivery = yield this.afs.collection('features').doc('delivery').collection('custom')
                    .snapshotChanges()
                    .pipe(map(snaps => convertSnaps(snaps)))
                    .pipe(first()).toPromise();
                resolve(customDelivery);
            }));
        });
    }
}
DeliverySettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeliverySettingsService_Factory() { return new DeliverySettingsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.LogglyLoggerService)); }, token: DeliverySettingsService, providedIn: "root" });
