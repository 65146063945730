import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class SharedNewService {

  constructor(private configService: ConfigService,) { }

  showQtyLeftMsg(product: any, index = 0) {
    const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
    let quantity = 0;
    quantity = product.isPriceList ? +product.priceList[index].totalQuantity || 0 : +product.productQty || 0; 
    return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
  }

  showPriceListQtyLeftMsg(quantity: number) {
    const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
    return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
  }
}
