import * as tslib_1 from "tslib";
import { Events, NavController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { convertSnaps } from '../db-utils';
import { Router } from '@angular/router';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import * as firebase from 'firebase';
import { encodeURL } from '../parseURL';
import { AngularFireStorage } from '@angular/fire/storage';
import { Storage } from '@ionic/storage';
import { VendorService } from '../vendor/vendor.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../label/label.service";
import * as i4 from "../shared/shared.service";
import * as i5 from "@angular/router";
import * as i6 from "../utils/utils.service";
import * as i7 from "../loggly-logger/loggly-logger.service";
import * as i8 from "@angular/fire/storage";
import * as i9 from "@ionic/storage";
import * as i10 from "../vendor/vendor.service";
export class WidgetsService {
    constructor(events, angularFirestore, labelService, sharedService, router, navCtrl, utilsService, logglyService, angularFireStorage, storage, vendorService) {
        this.events = events;
        this.angularFirestore = angularFirestore;
        this.labelService = labelService;
        this.sharedService = sharedService;
        this.router = router;
        this.navCtrl = navCtrl;
        this.utilsService = utilsService;
        this.logglyService = logglyService;
        this.angularFireStorage = angularFireStorage;
        this.storage = storage;
        this.vendorService = vendorService;
        this.widgetRefrence = this.angularFirestore.collection('widgets');
    }
    initializeSubscriptions() { }
    getCustomPageSections(pageId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const pageData = yield this.getSections(pageId);
                    resolve(pageData);
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'widgets-service:getCustomPageSections';
                    this.logglyService.log(error);
                    resolve({ pageName: '', sections: [] });
                }
            }));
        });
    }
    getSections(pageId = 'homepage') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let appSections = [];
                let sectionsDoc = yield this.angularFirestore.collection('pages').doc(pageId).valueChanges().pipe(first()).toPromise();
                if (sectionsDoc) {
                    if (sectionsDoc.sections && sectionsDoc.sections.length) {
                        sectionsDoc.sections.forEach((section) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            const regionId = (yield this.sharedService.checkRegionIdForApi()).regionId;
                            const sectionRegionIds = 'regionId' in section ? section.regionId : [];
                            let sectionAvailable = false;
                            if (section.location === 'web' || section.location === 'all') {
                                if (regionId) {
                                    if (sectionRegionIds.includes(regionId)) {
                                        sectionAvailable = true;
                                    }
                                }
                                else {
                                    sectionAvailable = true;
                                }
                            }
                            if (sectionAvailable) {
                                appSections.push(section);
                            }
                        }));
                    }
                }
                return pageId === 'homepage' ? appSections : { pageName: sectionsDoc.name || '', sections: appSections };
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'widgets-service:getSections';
                this.logglyService.log(error);
            }
        });
    }
    /************** Product Carousel*********************/
    getProductsForCarousel(widgetID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let returnData;
            let data = yield this.widgetRefrence.doc(widgetID).collection('products', ref => ref.orderBy('sortedAt', 'desc')).snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
            if (data && data.length) {
                returnData = data;
                return returnData;
            }
            else {
                returnData = false;
            }
        });
    }
    getProductsForList(widgetID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let data = yield this.widgetRefrence.doc(widgetID).collection('products', ref => ref.orderBy('sortedAt', 'desc')).snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
            if (!data.length) {
                this.events.publish('widgets:publishnoProductsForList');
            }
            else {
                let productsData = [];
                for (let index = 0; index < data.length; index++) {
                    let obj = {};
                    obj.id = data[index].id;
                    obj.data = yield this.angularFirestore.collection('products').doc(data[index].id).valueChanges().pipe(first()).toPromise();
                    productsData.push(obj);
                }
                //this.events.publish('widgets:publishProductsForList', productsData);
                return productsData;
            }
        });
    }
    /************** Product Carousel*********************/
    /************** banner*********************/
    getWebImagesForBanner(ID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let data = yield this.widgetRefrence.doc(ID).collection('webSlides', ref => ref.orderBy('createdAt', 'desc').where('active', '==', true)).snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                return data;
            }
            catch (error) { }
        });
    }
    getMobImagesForBanner(ID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let data = yield this.widgetRefrence.doc(ID).collection('slides', ref => ref.orderBy('createdAt', 'desc').where('active', '==', true)).snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                return data;
            }
            catch (error) { }
        });
    }
    /************** banner*********************/
    getWidgetData(ID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let data = yield this.angularFirestore.collection('widgets').doc(ID).valueChanges().pipe(first()).toPromise();
                return data;
                //this.events.publish('widgets:publishWidgetData', data);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'widgets-service:getWidgetData';
                this.logglyService.log(error);
            }
        });
    }
    getCBSDataFromId(id, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const data = yield this.angularFirestore.collection(type).doc(id).valueChanges().pipe(first()).toPromise();
                resolve(data || null);
            }));
        });
    }
    bannerLink(bannerData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (bannerData.hasOwnProperty('link')) {
                const userId = yield this.sharedService.getStorageUid();
                const linkType = bannerData.link.type;
                const id = 'ids' in bannerData.link ? bannerData.link.ids : (typeof bannerData.link.id === 'string' ? [bannerData.link.id] : bannerData.link.id);
                const name = bannerData.link.name || '';
                const idLength = id.length;
                const options = {
                    queryParams: {
                        values: JSON.stringify(id)
                    }
                };
                if (linkType === this.labelService.labels['BANNER_SETTINGS']['category']) {
                    if (idLength > 1) {
                        options.queryParams['type'] = 'categories';
                        this.router.navigate(['tagged-items'], options);
                    }
                    else {
                        let cname = encodeURL(name);
                        if (bannerData.link.hasOwnProperty('isSubcategories') && bannerData.link.isSubcategories) {
                            this.router.navigate([`shop-subcategories/${cname}/${id[0]}`]);
                        }
                        else {
                            // const category: any = await this.getCBSDataFromId(id[0], 'categories');
                            // this.sharedService.manageExclusiveCategories(category.isExclusive || false, id[0], 'parentCategory');
                            this.router.navigate([`shop/category/${cname}/${id[0]}`]);
                        }
                    }
                }
                else if (linkType === this.labelService.labels['BANNER_SETTINGS']['brand']) {
                    if (idLength > 1) {
                        options.queryParams['type'] = 'brands';
                        this.router.navigate(['tagged-items'], options);
                    }
                    else {
                        let brandname = encodeURL(name);
                        this.router.navigate([`shop/brand/${brandname}/${id[0]}`]);
                    }
                }
                else if (linkType === this.labelService.labels['BANNER_SETTINGS']['service']) {
                    const res = yield this.sharedService.checkLoginStatus(`${this.labelService.labels['CO_CATEGORY_GRID']['must_be_user_to_access_services_msg']}`);
                    if (res) {
                        let servicName = encodeURL(name);
                        this.router.navigate([`service-response/${servicName}/${id[0]}`]);
                    }
                }
                else if (linkType === this.labelService.labels['BANNER_SETTINGS']['refer_and_earn'] || linkType === 'referEarn') {
                    const userId = yield this.sharedService.getStorageUid();
                    if (!userId) {
                        this.utilsService.openLoginModal();
                    }
                    else {
                        this.router.navigate(['user-referral']);
                    }
                }
                else if (linkType === this.labelService.labels['BANNER_SETTINGS']['search']) {
                    const navigationExtras = {
                        state: {
                            searchTxt: name
                        }
                    };
                    this.router.navigate(['search-items'], navigationExtras);
                }
                else if (linkType === this.labelService.labels['BANNER_SETTINGS']['product']) {
                    if (idLength > 1) {
                        options.queryParams['type'] = 'products';
                        this.router.navigate(['tagged-items'], options);
                    }
                    else {
                        let productName = encodeURL(name);
                        this.router.navigate([`product-details/${productName}/${id[0]}`]);
                    }
                }
                else if (linkType === this.labelService.labels['BANNER_SETTINGS']['membership'] || linkType === 'membership') {
                    const userId = yield this.sharedService.getStorageUid();
                    if (!userId) {
                        this.utilsService.openLoginModal();
                    }
                    else {
                        this.router.navigate(['membership']);
                    }
                }
                else if (linkType === 'subcategory') {
                    const category = yield this.getCBSDataFromId(bannerData.link.categoryId, 'categories');
                    // let exclusiveObj = await this.storage.get('exclusiveObj');
                    // const isExclusive = exclusiveObj.categoryType === 'parentCategory' && exclusiveObj.exclusivity ? false : category.isExclusive;
                    // this.sharedService.manageExclusiveCategories(isExclusive || false, bannerData.link.categoryId, 'subCategory');
                    this.router.navigate([`shop/category/${this.getUrlTxt(category.name || '')}/${bannerData.link.categoryId}/${this.getUrlTxt(name || '')}/${id[0]}`]);
                }
                else if (linkType === 'page') {
                    let pageName = encodeURL(name);
                    // this.router.navigate(['custom-page'], options)
                    this.router.navigate([`custom-page/${pageName}/${id[0]}`]);
                }
                else if (linkType === 'external') {
                    if (bannerData.link.url) {
                        window.open(bannerData.link.url, '_blank');
                    }
                }
                else if (linkType === 'contactUs') {
                    this.router.navigate(['contact']);
                }
                else if (linkType === 'feedback') {
                    const userId = yield this.sharedService.getStorageUid();
                    if (!userId) {
                        this.utilsService.openLoginModal();
                    }
                    else {
                        this.router.navigate(['user-feedback']);
                    }
                }
                else if (linkType === 'offers') {
                    this.router.navigate(['all-offers']);
                }
                else if (linkType === 'pdf') {
                    if (bannerData.link.url) {
                        window.open(bannerData.link.url, '_blank');
                    }
                }
                else {
                    return null;
                }
            }
        });
    }
    getUrlTxt(value) {
        return encodeURL(value);
    }
    getMultipleTaggedItems(ids, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const documents = yield this.angularFirestore.collection(type, ref => ref
                        .where(firebase.firestore.FieldPath.documentId(), 'in', ids))
                        .snapshotChanges().pipe(map(snaps => convertSnaps(snaps)))
                        .pipe(first()).toPromise();
                    resolve(documents);
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'widgets-service:getMultipleTaggedItems';
                    this.logglyService.log(error);
                    resolve([]);
                }
            }));
        });
    }
    submitForm(formData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    for (const key of Object.keys(formData.formData)) {
                        if (formData.formData[key].includes(';base64')) {
                            const storageRef = this.angularFireStorage.ref(`forms/${formData.userId}/${key}`);
                            yield storageRef.putString(formData.formData[key], 'data_url');
                            const downloadURL = yield storageRef.getDownloadURL().pipe(first()).toPromise();
                            formData.formData[key] = downloadURL;
                        }
                    }
                    yield this.angularFirestore.collection('forms').add(formData);
                    resolve(true);
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'widgets-service:submitForm';
                    this.logglyService.log(error);
                    resolve(false);
                }
            }));
        });
    }
    checkFormAlreadySubmitted(multipleSubmissions) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const userId = yield this.sharedService.getStorageUid();
                    if (!userId) {
                        resolve('no_login');
                        return;
                    }
                    if (multipleSubmissions) {
                        resolve('not_submitted');
                        return;
                    }
                    const result = yield this.angularFirestore.collection('forms', ref => ref.where('userId', '==', userId)).valueChanges().pipe(first()).toPromise();
                    resolve(!result.length ? 'not_submitted' : 'submitted');
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'widgets-service:checkFormAlreadySubmitted';
                    this.logglyService.log(error);
                    resolve('error');
                }
            }));
        });
    }
    getVendors(vendorIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    let vendors = [];
                    const region = yield this.sharedService.checkRegionIdForApi();
                    for (const vendorId of vendorIds) {
                        const vendor = yield this.angularFirestore.collection('features').doc('multiVendor').collection('vendors').doc(vendorId)
                            .valueChanges().pipe(first()).toPromise();
                        if (vendor) {
                            if (region.regionId) {
                                if (region.regionId === (vendor.regionId || '')) {
                                    vendors.push(Object.assign({ id: vendorId }, vendor));
                                }
                            }
                            else {
                                vendors.push(Object.assign({ id: vendorId }, vendor));
                            }
                        }
                    }
                    if (this.vendorService.vendorSettings.vendorBasedOnKm) {
                        let finalVendors = yield this.getVendorsBasedOnDistance(vendors);
                        this.vendorService.vendorSettings.vendors = finalVendors;
                        resolve(finalVendors);
                    }
                    else {
                        this.vendorService.vendorSettings.vendors = vendors;
                        resolve(vendors);
                    }
                }
                catch (error) {
                    console.log('error', error);
                }
            }));
        });
    }
    getUserLocation() {
        return { lat: this.vendorService.userPlace.lat, lng: this.vendorService.userPlace.lng };
    }
    makeArrayGroups(array, groupSize) {
        const result = [];
        for (let index = 0; index < array.length; index += groupSize) {
            const group = array.slice(index, index + groupSize);
            result.push(group);
        }
        console.log('result', result);
        return result;
    }
    getVendorsBasedOnDistance(vendors) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let vendorGroups = this.makeArrayGroups(vendors, 25); //Google Disatnce api limited to "25" destinations lat & lng;
                const userLocation = this.getUserLocation();
                if (!userLocation.lat || !userLocation.lng) {
                    resolve([]);
                }
                else {
                    const origins = [new google.maps.LatLng(userLocation.lat, userLocation.lng)];
                    const destinations = [];
                    const distances = [];
                    for (const vendorGroup of vendorGroups) {
                        for (const vendor of vendorGroup) {
                            if (vendor && vendor.vendorAddress && vendor.vendorAddress.lat && vendor.vendorAddress.lng) {
                                destinations.push({ id: vendor.id, latLng: new google.maps.LatLng(vendor.vendorAddress.lat, vendor.vendorAddress.lng) });
                            }
                        }
                        const oneGroupDistances = yield this.distanceApi(origins, destinations);
                        for (const d of oneGroupDistances) {
                            distances.push(d);
                        }
                    }
                    for (const vendorGroup of vendorGroups) {
                        for (const vendor of vendorGroup) {
                            const index = distances.findIndex(d => d.id === vendor.id);
                            if (index !== -1) {
                                vendor['distance'] = distances[index].distance;
                            }
                            else {
                                vendor['distance'] = 0;
                            }
                        }
                    }
                    let allVendors = vendorGroups.flat(1);
                    let vendors = [];
                    allVendors.forEach(vendor => {
                        if (vendor.distance) {
                            vendors.push(vendor);
                        }
                    });
                    vendors.sort((a, b) => {
                        return a.distance - b.distance;
                    });
                    console.log('vendors', vendors);
                    resolve(vendors);
                }
            }));
        });
    }
    distanceApi(origins, destinations) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const matrix = new google.maps.DistanceMatrixService();
            let _this = this;
            return new Promise((resolve) => {
                const destinationsLatLng = [];
                for (const destination of destinations) {
                    destinationsLatLng.push(destination.latLng);
                }
                matrix.getDistanceMatrix({
                    origins,
                    destinations: destinationsLatLng,
                    travelMode: google.maps.TravelMode.DRIVING,
                }, function (response, status) {
                    console.log('response', response);
                    if (status === 'OK') {
                        if (response.rows && response.rows.length && response.rows[0].elements) {
                            const results = response.rows[0].elements;
                            for (let index = 0; index < results.length; index++) {
                                let distance = results[index].status === 'OK' && results[index].distance && results[index].distance.value ? results[index].distance.value / 1000 : 0;
                                if (distance > _this.vendorService.vendorSettings.vendorDistance) {
                                    distance = 0;
                                }
                                destinations[index]['distance'] = distance;
                            }
                            resolve(destinations);
                        }
                        else {
                            resolve([]);
                        }
                    }
                    else {
                        resolve([]);
                    }
                });
            });
        });
    }
}
WidgetsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WidgetsService_Factory() { return new WidgetsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LabelService), i0.ɵɵinject(i4.SharedService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i6.UtilsService), i0.ɵɵinject(i7.LogglyLoggerService), i0.ɵɵinject(i8.AngularFireStorage), i0.ɵɵinject(i9.Storage), i0.ɵɵinject(i10.VendorService)); }, token: WidgetsService, providedIn: "root" });
