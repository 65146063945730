import { ApplicationDirectivesModule } from 'src/app/directives/application-directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ChatModalComponent } from './chat-modal/chat-modal.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { BannerSliderComponent } from './banner-slider/banner-slider.component';
import { Routes, RouterModule } from '@angular/router';
import { ItemGridComponent } from './item-grid/item-grid.component';
import { SocialShareComponent } from './social-share/social-share.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { CategoryGridComponent } from './category-grid/category-grid.component';
import { ProductGridComponent } from './product-grid/product-grid.component';
import { RatingComponent } from './rating/rating.component';
import { StarRatingModule } from 'ionic4-star-rating';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetBannerSliderComponent } from './widgets/widget-banner-slider/widget-banner-slider.component';
import { WidgetBrandsComponent } from './widgets/widget-brands/widget-brands.component';
import { WidgetCategoriesComponent } from './widgets/widget-categories/widget-categories.component';
import { WidgetImagesBannerComponent } from './widgets/widget-images-banner/widget-images-banner.component';
import { WidgetProductListComponent } from './widgets/widget-product-list/widget-product-list.component';
import { WidgetServicesComponent } from './widgets/widget-services/widget-services.component';
import { TestComponentComponent } from './test-component/test-component.component';
import { WidgetProductCarouselComponent } from './widgets/widget-product-carousel/widget-product-carousel.component';
import { WidgetVideoBlockComponent } from './widgets/widget-video-block/widget-video-block.component';
import { WidgetImageBlockComponent } from './widgets/widget-image-block/widget-image-block.component';
import { WidgetTextBlockComponent } from './widgets/widget-text-block/widget-text-block.component';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';
import { WidgetsComponent } from './widgets/widgets/widgets.component';
import { WidgetFormComponent } from './widgets/widget-form/widget-form.component';
import { ProductThumbOneComponent } from './product/product-thumb-one/product-thumb-one.component';
import { FilterComponent } from './filter/filter.component';
import { PickDropHistoryComponent } from './pick-drop-history/pick-drop-history.component';
import { WidgetDocumentComponent } from './widgets/widget-document/widget-document.component';
import { HeaderTestComponent } from './header/header-test/header-test.component';
import { TestFooterComponent } from './footer/test-footer/test-footer.component';
import { HeaderOneComponent } from './header/header-one/header-one.component';
import { HeaderThreeComponent } from './header/header-three/header-three.component';
import { HeaderFourComponent } from './header/header-four/header-four.component';
import { HeaderFiveComponent } from './header/header-five/header-five.component';
import { HeaderSixComponent } from './header/header-six/header-six.component';
import { HeaderTwoComponent } from './header/header-two/header-two.component';
import { ProductThumbTwoComponent } from './product/product-thumb-two/product-thumb-two.component';
import { ProductThumbThreeComponent } from './product/product-thumb-three/product-thumb-three.component';
import { ProductThumbFourComponent } from './product/product-thumb-four/product-thumb-four.component';
import { ProductThumbFiveComponent } from './product/product-thumb-five/product-thumb-five.component';
import { PrductThumbComponent } from './product/prduct-thumb/prduct-thumb.component';
import { WidgetVendorsComponent } from './widgets/widget-vendors/widget-vendors.component';
import { ItemThumbOneComponent } from './item-grid/item-thumb-one/item-thumb-one.component';
import { ItemThumbTwoComponent } from './item-grid/item-thumb-two/item-thumb-two.component';
import { ProductThumbSixComponent } from './product/product-thumb-six/product-thumb-six.component';
import { ItemThumbThreeComponent } from './item-grid/item-thumb-three/item-thumb-three.component';
import { ItemThumbFourComponent } from './item-grid/item-thumb-four/item-thumb-four.component';
import { ItemThumbFiveComponent } from './item-grid/item-thumb-five/item-thumb-five.component';
import { ItemThumbSixComponent } from './item-grid/item-thumb-six/item-thumb-six.component';
import { ItemThumbSevenComponent } from './item-grid/item-thumb-seven/item-thumb-seven.component';
import { ProductThumbSevenComponent } from './product/product-thumb-seven/product-thumb-seven.component';
import { ProductThumbEightComponent } from './product/product-thumb-eight/product-thumb-eight.component';
import { AppointmentComponent } from './product/types/appointment/appointment.component';
import { HeaderDefaultComponent } from './header/header-default/header-default.component';
import { HeaderSevenComponent } from './header/header-seven/header-seven.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ApplicationDirectivesModule,
        RouterModule,
        StarRatingModule,
        TranslateModule,
        ApplicationPipesModule,
        ReactiveFormsModule
    ],
    declarations: [
        ChatModalComponent,
        PageHeaderComponent,
        PageFooterComponent,
        BannerSliderComponent,
        ItemGridComponent,
        SocialShareComponent,
        ServiceDetailsComponent,
        CategoryGridComponent,
        ProductGridComponent,
        RatingComponent,
        WidgetBannerSliderComponent,
        WidgetBrandsComponent,
        WidgetCategoriesComponent,
        WidgetImagesBannerComponent,
        WidgetProductListComponent,
        WidgetServicesComponent,
        WidgetProductCarouselComponent,
        TestComponentComponent,
        WidgetVideoBlockComponent,
        WidgetImageBlockComponent,
        WidgetTextBlockComponent,
        BannerSliderComponent,
        WidgetsComponent,
        WidgetFormComponent,
        ProductThumbOneComponent,
        FilterComponent,
        WidgetDocumentComponent,
        HeaderTestComponent,
        TestFooterComponent,
        HeaderDefaultComponent,
        HeaderOneComponent,
        HeaderTwoComponent,
        HeaderThreeComponent,
        HeaderFourComponent,
        HeaderFiveComponent,
        HeaderSixComponent,
        HeaderSevenComponent,
        PickDropHistoryComponent,
        ProductThumbTwoComponent,
        ProductThumbThreeComponent,
        ProductThumbFourComponent,
        ProductThumbFiveComponent,
        ProductThumbSixComponent,
        ProductThumbSevenComponent,
        ProductThumbEightComponent,
        PrductThumbComponent,
        WidgetVendorsComponent,
        ItemThumbOneComponent,
        ItemThumbTwoComponent,
        ItemThumbThreeComponent,
        ItemThumbFourComponent,
        ItemThumbFiveComponent,
        ItemThumbSixComponent,
        ItemThumbSevenComponent,
        AppointmentComponent
    ],
    exports: [
        ChatModalComponent,
        PageHeaderComponent,
        PageFooterComponent,
        BannerSliderComponent,
        ItemGridComponent,
        SocialShareComponent,
        ServiceDetailsComponent,
        CategoryGridComponent,
        ProductGridComponent,
        RatingComponent,
        WidgetBannerSliderComponent,
        WidgetBrandsComponent,
        WidgetCategoriesComponent,
        WidgetImagesBannerComponent,
        WidgetProductListComponent,
        WidgetServicesComponent,
        WidgetProductCarouselComponent,
        TestComponentComponent,
        WidgetVideoBlockComponent,
        WidgetImageBlockComponent,
        WidgetTextBlockComponent,
        BannerSliderComponent,
        WidgetsComponent,
        WidgetFormComponent,
        ProductThumbOneComponent,
        FilterComponent,
        WidgetDocumentComponent,
        HeaderTestComponent,
        TestFooterComponent,
        HeaderDefaultComponent,
        HeaderOneComponent,
        HeaderTwoComponent,
        HeaderThreeComponent,
        HeaderFourComponent,
        HeaderFiveComponent,
        HeaderSixComponent,
        HeaderSevenComponent,
        PickDropHistoryComponent,
        ProductThumbTwoComponent,
        ProductThumbThreeComponent,
        ProductThumbFourComponent,
        ProductThumbFiveComponent,
        ProductThumbSixComponent,
        ProductThumbSevenComponent,
        ProductThumbEightComponent,
        PrductThumbComponent,
        WidgetVendorsComponent,
        ItemThumbOneComponent,
        ItemThumbTwoComponent,
        ItemThumbThreeComponent,
        ItemThumbFourComponent,
        ItemThumbFiveComponent,
        ItemThumbSixComponent,
        ItemThumbSevenComponent,
        AppointmentComponent
    ]
})

export class SharedModule { }
