import * as tslib_1 from "tslib";
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { HomePage } from 'src/app/home/home.page';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class UtilsService {
    constructor(modalController, platform, loadingController, alertController) {
        this.modalController = modalController;
        this.platform = platform;
        this.loadingController = loadingController;
        this.alertController = alertController;
    }
    openLoginModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: HomePage,
                backdropDismiss: false,
                cssClass: "custom-modal login-modal",
            });
            modal.onDidDismiss().then(() => {
            });
            yield modal.present();
        });
    }
    consoleLog(key, log) {
        //console.log(key, log)
    }
    getDeviceWidth() {
        return this.platform.width();
    }
    presentAlert(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                buttons: ['OK']
            });
            yield alert.present();
        });
    }
    presentLoading(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: msg,
                duration: 9000,
            });
            yield this.loading.present();
        });
    }
}
UtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.AlertController)); }, token: UtilsService, providedIn: "root" });
