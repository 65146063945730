<ion-content>
        <ion-button (click)="modalDismiss()" class="close-btn" fill="clear" color="dark">
                <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>

        <div class="recaptcha-box">
                <div class="recaptcha-container" [hidden]="mode == 'otp' || showNameInput">
                        <div id="recaptcha-container"></div>
                </div>
        </div>


        <div class="center-container">
                <div class="logo">
                        <img src="../../../assets/img/shop-logo-center.png" [alt]="storeData.storeName">
                </div>

                <div class="pincode-section" *ngIf="showPincode">

                        <div class="pincode-section-content input-wrap">
                                <p>{{'HOME.where_do_you_want_the_delivery' | translate}}</p>
                                <!-- <ion-text>{{'HOME.please_enter_pincode' | translate}}</ion-text> -->


                                <ion-input type="tel" placeholder="{{'HOME.pincode_placeholder' | translate}}"
                                        class="input-box" class="form-input" [(ngModel)]="pincode"
                                        (ionChange)="pincodeChange()" maxlength="6">
                                </ion-input>

                                <div *ngIf="notDeliverable" class="txt-error ion-text-center">
                                        {{'HOME.delivery_not_available' | translate}}</div>
                                <div class="skip-btn m-t-16">
                                        <ion-button color="dark" (click)="skipPincodeCheck()" fill="outline"
                                                size="small" shape="round">
                                                {{'HOME.skip' | translate}}
                                        </ion-button>
                                </div>
                        </div>
                </div>

                <ng-container *ngIf="showSignin">


                        <div class="input-wrap" *ngIf="!showNameInput">
                                <ion-label class="ion-text-center"> {{'HOME.login_with_number' | translate}}</ion-label>
                                <div class="phone-wrapper">
                                        <div class="pre-fix"><i class="flaticon-smartphone"></i> {{countryCode}}</div>
                                        <ion-input type="tel" placeholder="{{'HOME.phone_placeholder' | translate}}"
                                                [(ngModel)]="phoneNo" class="form-input tel" [maxlength]="phoneLength"
                                                (ionChange)="checkError()">
                                        </ion-input>
                                </div>
                        </div>

                        <div class="input-wrap" *ngIf="confirmationResult && !showNameInput">

                                <ion-input type="" placeholder="{{'HOME.otp_placeholder' | translate}}"
                                        class="form-input" [(ngModel)]="verificationCode" (ionChange)="checkError()">
                                </ion-input>
                                <!-- resend otp-->
                        <div class="resend-otp">
                                <span class="resend-otp-timer" *ngIf="showTimer">00:<span
                                        *ngIf="timer >= 10; else appendZeroToTimer">{{timer}}</span>
                                     <ng-template #appendZeroToTimer>
                                        <span *ngIf="timer < 10">0{{timer}}</span>
                                     </ng-template>
                                  </span><br>
                                <ion-button class="btn-2" (click)="reSendOtp()" [disabled]="!showResendBtn" fill="clear">
                                        {{'HOME.resend_otp' | translate}}
                                </ion-button>
                            
                            </div>
                          <!-- resend otp--> 

                                

                        </div>
                         
                        
                        <div class="txt-error ion-text-center" *ngIf="error">{{errorMsg}}</div>
                        <ng-container *ngIf="showNameInput">
                                <div class="input-wrap" *ngIf="loginPopupSettings.name">
                                        <ion-input type="text" placeholder="{{'HOME.name_placeholder' | translate}}"
                                                class="form-input" [(ngModel)]="userName">
                                        </ion-input>
                                </div>
                                <div class="input-wrap" *ngIf="loginPopupSettings.email">
                                        <ion-input type="email" placeholder="{{'HOME.email_placeholder' | translate}}"
                                                class="form-input" [(ngModel)]="userEmail">
                                        </ion-input>
                                </div>

                                <div class="input-wrap" *ngIf="loginPopupSettings.dob">
                                        <ion-datetime placeholder="{{'HOME.dob_placeholder' | translate}}"
                                                class="form-input" placeholder="Enter Birth Date" [(ngModel)]="birthday"
                                                displayFormat="DD/MM/YYYY" [max]="maxDate"></ion-datetime>
                                </div>

                                <div class="input-wrap" *ngIf="loginPopupSettings.gst">
                                        <ion-input type="text" placeholder="{{taxType}}" class="form-input"
                                                [(ngModel)]="userGst">
                                        </ion-input>
                                </div>

                                <div class="input-wrap" *ngIf="loginPopupSettings.custom.active">
                                        <ion-input type="text" placeholder="{{loginPopupSettings.custom.name}}"
                                                class="form-input" [(ngModel)]="customInput">
                                        </ion-input>
                                </div>
                        </ng-container>



                        <div *ngIf="newUser" class="social-login-container">
                                <ion-button (click)="sendOtp()" class="btn-2">
                                        {{'HOME.sign_in_with_otp' | translate}}
                                </ion-button>
                                <div class="social-login" *ngIf="socialSigin">
                                        <div class="social-login-btn fb">
                                                <ion-button (click)="fbLogin()" class="btn-2 i-start facebook"
                                                        expand="full"><i class="flaticon-facebook-1"></i>
                                                        {{'HOME.sign_in_with_fb' | translate}}</ion-button>
                                        </div>
                                        <div class="social-login-btn">
                                                <ion-button (click)="googleLogin()" class="btn-2 i-start google"
                                                        expand="full">
                                                        <i class="flaticon-google-symbol"></i>
                                                        {{'HOME.sign_in_with_google' | translate}}
                                                </ion-button>
                                        </div>
                                </div>
                        </div>
                </ng-container>

                <div class="btn-wrap">

                        <ion-button (click)="checkPincode()" class="btn-2" *ngIf="showPincode"
                                [disabled]="!pincode || pincode.length < 6">
                                {{'HOME.check_pincode' | translate}}
                        </ion-button>

                        <ng-container *ngIf="showSignin">


                                <ion-button (click)="signIn()" class="btn-2"
                                        *ngIf="mode == 'mobile' && !isLoading && !showNameInput">
                                        {{'HOME.next' | translate}}
                                </ion-button>

                                <ion-button (click)="verifyLoginCode()" class="btn-2"
                                        *ngIf="mode == 'otp' && !isLoading && !showNameInput">
                                        {{'HOME.login' | translate}}
                                </ion-button>
                                <ion-button disabled class="btn-2" *ngIf="isLoading">
                                        {{loadingTxt}}
                                </ion-button>
                                <ion-button (click)="updateUser()" class="btn-2" *ngIf="!isLoading && showNameInput">
                                        {{'HOME.save' | translate}}
                                </ion-button>
                        </ng-container>
                </div>
        </div>


</ion-content>